import fetcher from '@utils/fetcher';

const CartServices = {
    getCart: (token, basketHash) => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(
            basketHash ? `v2/basket?basket_hash=${basketHash}` : 'v2/basket',
            options,
        );
    },
    addToCart: ({ productID, quantity, overwrite, token, basketHash }) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                productId: Number(productID),
                quantity: quantity || 1,
                overwrite: overwrite || false,
            }),
        };

        return fetcher(
            basketHash
                ? `v2/basket/add-product?basket_hash=${basketHash}`
                : 'v2/basket/add-product',
            options,
        );
    },
    removeFromCart: ({ productID, token, basketHash }) => {
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(
            `v2/basket/remove-product/${productID}?basket_hash=${basketHash}`,
            options,
        );
    },
    addCoupon: ({ couponCode, token, basketHash }) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                couponCode,
            }),
        };
        return fetcher(
            `v2/basket/add-coupon/?basket_hash=${basketHash}`,
            options,
        );
    },
    discardCoupon: ({ token, basketHash }) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(
            `v2/basket/discard-coupon/?basket_hash=${basketHash}`,
            options,
        );
    },
    addBox: ({ note, token, basketHash }) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                note,
            }),
        };
        return fetcher(
            `v2/basket/gift-box/?basket_hash=${basketHash}`,
            options,
        );
    },
    deleteBox: ({ token, basketHash }) => {
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(
            `v2/basket/discard-gift-box?basket_hash=${basketHash}`,
            options,
        );
    },
    cancelRequest: ({ token, id, reason }) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                reason,
            }),
        };
        return fetcher(
            `v1/sale/cancel-request/${id}`,
            options,
        );
    },
};

export default CartServices;
