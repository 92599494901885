import { useRouter } from 'next/router';
import defaultLocalesConfig from '@lib/localesConfig';
import { useCookies } from 'react-cookie';
import en from '../public/locales/en';
import tr from '../public/locales/tr';

export default function useTranslation() {
    const router = useRouter();
    const [cookie] = useCookies([
        'PREFERRED_LANG',
    ]);

    const {
        lang,
        currency,
        currencySymbol,
        countryID,
        dialCode,
    } = defaultLocalesConfig[router.locale || 'tr'];
    const preferredLang = cookie?.PREFERRED_LANG || lang;
    const translate = preferredLang === 'tr' ? tr : en;

    function t(key) {
        if (!translate[key]) {
            console.warn(
                `Translation '${key}' for locale '${preferredLang}' not found.`,
            );
        }
        return translate[key] || '';
    }

    function useCurrency(price) {
        if (!price) return null;
        const priceFinal = price[currency];
        return (
            `${new Intl.NumberFormat('tr', {
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(priceFinal)} ${currencySymbol}`
        );
    }
    return {
        currency,
        locale: preferredLang,
        countryID,
        dialCode,
        t,
        useCurrency,
    };
}
