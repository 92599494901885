import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useWindowSize } from 'react-use';
import { useModal } from 'context/ModalContext';
import { Main } from 'components/styles/CommonStyles';
import { Header, MobileHeader } from '@components/common';
import { Topbar } from '@components/ui';

import { LangModal } from '@components/homepage';
import { useCookies } from 'react-cookie';
import { useAccount } from '@context/AccountContext';

const AskCookie = dynamic(
    () => import('components/common/CookieSettings/AskCookie'),
    {
        ssr: false,
    },
);
const BankIcons = dynamic(() => import('components/ui/BankIcons'), {
    ssr: false,
});
const Search = dynamic(() => import('@components/common/Search/'), {
    ssr: false,
});
const Footer = dynamic(() => import('@components/common/Footer/Footer'), {
    ssr: false,
});
const LeftMenu = dynamic(() => import('@components/common/Nav/LeftMenu'), {
    ssr: false,
});

const Layout = ({ children, font }) => {
    const router = useRouter();

    const [cookie] = useCookies([
        'user_cookie',
        'firstSignupCampaign',
    ]);
    const [userHistory, setUserHistory] = useState([]);
    const { width } = useWindowSize();
    const { pathname, query, asPath } = router;
    const { closeAllModal } = useModal();

    const { userData, currentUser } = useAccount();



    const hideMobileMenu =
        pathname.includes('login') ||
        pathname.includes('signup') ||
        pathname.includes('checkout') ||
        pathname.includes('forgotPassword') ||
        pathname.includes('account') ||
        pathname.includes('cart') ||
        pathname.includes('checkout') ||
        pathname.includes('about-us') ||
        pathname.includes('faq') ||
        pathname.includes('contact') ||
        pathname.includes('all-categories') ||
        pathname.includes('privacy-policy') ||
        pathname.includes('cookie-policy') ||
        pathname.includes('storage-and-destroy') ||
        pathname.includes('distance-sale-contract') ||
        pathname.includes('change-and-return') ||
        pathname.includes('application-form') ||
        pathname.includes('membership-info') ||
        pathname.includes('membership-agreement') ||
        pathname.includes('resetPassword');
    const mainBackgroundGray = pathname.includes('register');
    const hideFooter =
        pathname.includes('login') ||
        pathname.includes('signup') ||
        pathname.includes('forgotPassword') ||
        pathname.includes('resetPassword') ||
        pathname.includes('account') ||
        pathname.includes('resetPassword') ||
        pathname.includes('cart') ||
        pathname === '/' ||
        pathname.includes('checkout');

    const hideBankIcons =
        pathname.includes('account') ||
        pathname.includes('login') ||
        pathname.includes('signup') ||
        pathname.includes('forgotPassword') ||
        pathname.includes('resetPassword') ||
        pathname.includes('account') ||
        pathname === '/' ||
        pathname.includes('resetPassword');
    const hideBankIconsTablet = pathname.includes('checkout');

    useEffect(() => {
        closeAllModal();
        setUserHistory([asPath, ...userHistory]);
    }, [asPath, query]);

    useEffect(() => {
        if (userHistory.length > 10) {
            setUserHistory(userHistory.slice(0, 10));
        }
    }, [userHistory]);

    const userName =
        currentUser && userData && userData.data?.data
            ? `${userData.data?.data?.first_name} ${userData.data?.data?.last_name}`
            : null;
    const userEmail =
        currentUser && userData && userData.data?.data
            ? userData.data?.data?.email
            : null;

    return (
        <main className={font}>
            <Topbar />

            <LeftMenu name={userName} email={userEmail} />
            <Search />
            <Main mainBackgroundGray={mainBackgroundGray}>{children}</Main>
            {!hideMobileMenu && <MobileHeader />}
            <Header />
            {!hideFooter && <Footer />}
            {!hideBankIcons && (
                <BankIcons hideIcon={hideBankIconsTablet || width < 768} />
            )}

            <LangModal />
            {!cookie?.user_cookie && <AskCookie />}
        </main>
    );
};

export default Layout;
